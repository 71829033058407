import { useState, useEffect } from 'react';
import { LoadYogaData } from './services/mindbodyService';

export const YogaInfo = () => {
  const [yogaClasses, setYogaClasses] = useState(null);
  let classlist = []

  useEffect(() => {
    LoadYogaData().then((classes) => {
      classlist = classlist.concat(classes);
      LoadYogaData(1).then((classes1) => {
        classlist = classlist.concat(classes1);
        LoadYogaData(2).then((classes2) => {
          classlist = classlist.concat(classes2);
          setYogaClasses(classlist)
        });    
      });    
    });
  }, []);

  if (!yogaClasses) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h3>Next 3 Days @ The Space</h3>
      <div className='table-container'>
        <table className="table no-wrap">
          <thead>
          <tr>
            <th>
              Day
            </th>
            <th>
              Time
            </th>
            <th>
              Teacher
            </th>
            <th>
              Type
            </th>
          </tr>
          </thead>
          <tbody>
            {yogaClasses.map(item => (
              <tr key={item.id}>
                <td key={item.day}>{item.day}</td>
                <td key={item.time}>{item.time}</td>
                <td key={item.staff}>{item.staff}</td>
                <td key={item.name}>{item.name}</td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
    </div>

  );
}